<template>
  <div class="container page__inner">
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="accountLoading"
      style="width: 100%"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--600 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>

    <!-- <Sidebar
      v-if="!isLoading"
      :data="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
    /> -->

    <CustomerAside
      v-if="!accountLoading"
      v-bind:customer-name="accountFullName"
      v-bind:customer-key="
        account_details ? account_details.data.accountHolderKey : ''
      "
      @imageAlert="imageAlert"
      :accountKey="loanKey"
      :customerLoanDeposit="customerLoanDeposits"
    ></CustomerAside>

    <main class="page__content2" v-if="!accountLoading">
      <!--ALERT NOTIFICATION-->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
            'toaster--warn': alert.status === 'warn',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>

      <!--CONFIRM DIALOG-->
      <div
        class="modal modal__confirm show"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        v-bind:style="{ display: confirm_status ? 'block' : 'none' }"
        aria-hidden="true"
      >
        <div class="w-100 h-100 d-flex">
          <div class="modal__dialog modal__dialog_alert" role="document">
            <div class="modal__header mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <span class="modal__header__title text--capital">
                  confirmation
                </span>
                <a
                  class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                  @click="confirmStatus()"
                >
                  <span class="text--capital text--regular"> close </span>
                  <ion-icon name="close-outline"></ion-icon>
                </a>
              </div>
              <span class="modal__header__subtitle text--capital">
                For Loan Product
              </span>
            </div>
            <div class="modal__content">
              <div class="modal__body">
                <h5 class="modal__head__title text--capital mb-3">
                  Are you sure you want to proceed?
                </h5>
                <p class="form__label"></p>
                <div class="d-flex mt-5">
                  <button
                    data-dismiss="#confirmModal"
                    class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                    @click="confirmStatus()"
                  >
                    cancel
                  </button>
                  <button
                    data-dismiss="#confirmModal"
                    class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                    @click="updateStatus()"
                  >
                    {{
                      process
                        ? "Processing..."
                        : loanProductStatus
                        ? "Deactivate"
                        : "Activate"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--CONFIRM DELETE DIALOG-->
      <div
        class="modal modal__confirm show"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
        aria-hidden="true"
      >
        <div class="w-100 h-100 d-flex">
          <div class="modal__dialog modal__dialog_alert" role="document">
            <div class="modal__header mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <span class="modal__header__title text--capital">
                  confirmation
                </span>
                <a
                  class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                  @click="confirmDelete()"
                >
                  <span class="text--capital text--regular"> close </span>
                  <ion-icon name="close-outline"></ion-icon>
                </a>
              </div>
              <span class="modal__header__subtitle text--capital">
                For Loan Product
              </span>
            </div>
            <div class="modal__content">
              <div class="modal__body">
                <h5 class="modal__head__title text--capital mb-3">
                  Are you sure you want to proceed?
                </h5>
                <p class="form__label"></p>
                <div class="d-flex mt-5">
                  <button
                    data-dismiss="#confirmModal"
                    class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                    @click="confirmDelete()"
                  >
                    cancel
                  </button>
                  <button
                    data-dismiss="#confirmModal"
                    class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                    @click="deleteItem()"
                  >
                    {{ process ? "Processing..." : "Delete" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="page__head d-flex">
          <div
            class="page__head__container ml-auto align-items-center justify-content-between"
          >
            <div class="page__head__actions">
              <div class="d-flex">
                <button
                  type="button"
                  class="mx-2 button page__body__header__button button--grey text--capital"
                  @click="goBackToDisbursement"
                >
                  <span class="d-inline-flex mr-2"
                    >Go back to disbursement</span
                  >
                </button>

                <button
                  type="button"
                  class="mx-2 button page__body__header__button button--grey text--capital"
                  @click="openDisbursement"
                >
                  <span class="d-inline-flex mr-2">Confirm disbursement</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page__body">
        <div>
          <div class="page__body__header">
            <ul
              class="nav page__tabs page__tabs3 loan__page__tabs"
              id="page-Tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active text--capital"
                  id="pills-details-tab"
                  data-toggle="tab"
                  href="#pills-details"
                  role="tab"
                  aria-controls="pills-details"
                  aria-selected="true"
                  >details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-schedule-tab"
                  @click="getAccountSchedule(loanKey)"
                  data-toggle="tab"
                  href="#pills-schedule"
                  role="tab"
                  aria-controls="pills-schedule"
                  aria-selected="false"
                  >schedule</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-transactions-tab"
                  @click="getTransactions"
                  data-toggle="tab"
                  href="#pills-transactions"
                  role="tab"
                  aria-controls="pills-transactions"
                  aria-selected="false"
                  v-if="userCanViewLoanTransactions"
                  >transactions</a
                >
              </li>
              <!-- <li class="nav-item">
              <a class="nav-link text--capital" id="pills-security-tab" data-toggle="tab" href="#pills-security" role="tab" aria-controls="pills-security" aria-selected="false">securites</a>
            </li> -->
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-activities-tab"
                  @click="getActivities(loanKey)"
                  data-toggle="tab"
                  href="#pills-activities"
                  role="tab"
                  aria-controls="pills-activities"
                  aria-selected="false"
                  v-if="userCanViewAttachments"
                  >activities</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-attachments-tab"
                  @click="getAttachments(loanKey)"
                  data-toggle="tab"
                  href="#pills-attachments"
                  role="tab"
                  aria-controls="pills-attachments"
                  aria-selected="false"
                  >attachments</a
                >
              </li>
              <li class="nav-item" v-if="userCanViewComment">
                <a
                  class="nav-link text--capital"
                  id="pills-comments-tab"
                  @click="getComments"
                  data-toggle="tab"
                  href="#pills-comments"
                  role="tab"
                  aria-controls="pills-comments"
                  aria-selected="false"
                  >comments</a
                >
              </li>
            </ul>
          </div>
          <div class="tab-content" id="page-TabsContent">
            <TransactionsTab
              :data="uiProps.accountTransactions"
              @edit="editTransaction"
              @view="viewTransaction"
              @show-adjusted-transactions="toggleTransactions"
            />
            <AccountSchedulesTab
              :data="uiProps.accountSchedule"
              :loading="uiProps.loadingAccountSchedule"
            />
            <AccountActivitiesTab
              :data="uiProps.accountActivities"
              :loading="uiProps.loadingAccountActivities"
            />
            <AccountAttachmentsTab
              :data="uiProps.accountAttachments"
              @download-error="alertError"
              @delete-successful="alertSuccess"
              @reload-attachments="getUploadedAttachments"
              @reload-activities="getActivity"
              @preview-modal="previewFile"
              @toggle-attachment-modal="toggleAttachmentModal"
              :loading="uiProps.loadingAccountAttachments"
            />
            <AccountCommentsTab
              :data="
                uiProps.accountComments ? uiProps.accountComments.items : []
              "
              :loading="uiProps.loadingAccountComments"
              @edit="openEditComment"
              @refresh="getCommentsUpdate"
              @reload-activities="getActivity"
              @toggle-comment-modal="togleCommentModal"
              @alertSuccess="alertSuccess"
              @alertError="alertError"
              :userCanCreateComment="userCanCreateComment"
              :userCanEditComment="userCanEditComment"
              :userCanDeleteComment="userCanDeleteComment"
            />
            <div
              class="tab-pane fade show active"
              id="pills-details"
              role="tabpanel"
              aria-labelledby="pills-details-tab"
            >
              <div class="row mt5 mb5">
                <div class="col-4 m-auto">
                  <div class="details-card details-card--orange">
                    <span class="card_title d-block"> Total Balance </span>
                    <span class="card_subtitle d-block">
                      {{ account_details.data.loanBalance | currency }}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <FirstLevelDetails
                  :data="
                    account_details
                      ? account_details.data
                        ? account_details.data
                        : null
                      : null
                  "
                  :enums="newEnums"
                />
                <SecondLevelDetails
                  :data="uiProps.accountCustomFieldSets"
                  :userCanViewLoanAccount="userCanViewLoanAccount"
                  :userCanEditLoanAccount="userCanEditLoanAccount"
                  @editField="editCustomField"
                  @newField="addNewCustomField"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if="userIsUnauthorized" style="width: 100%">
      <h4 class="text-center mt-5 text-danger">{{ error }}</h4>
    </div>

    <DeleteModal
      :show="userIsUnauthorized"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <h5 class="text-danger">
              {{ error }}
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- ACTION MODALS  -->
    <EnterRepaymentModal
      :data="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="openRepaymentModal"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @alertWarning="alertWarning"
      @refresh="getTransactions"
      @get-details="getAccountDetails"
      @get-schedule="getAllReschedules"
      @close-repayment="enterRepayment"
    />

    <CloseRefinanceModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :customer="
        account_details
          ? account_details.data
            ? account_details.data.customerInformationDetails
              ? account_details.data.customerInformationDetails.data
              : null
            : null
          : null
      "
      :enums="newEnums"
      :show="openCloseRefinance"
      @alertError="alertError"
      @close="toggleRefinanceModal"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
    />

    <CloseRescheduleModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :customer="
        account_details
          ? account_details.data
            ? account_details.data.customerInformationDetails
              ? account_details.data.customerInformationDetails.data
              : null
            : null
          : null
      "
      :enums="newEnums"
      :show="openRescheduleModal"
      @close="toggleRescheduleModal"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
    />

    <AddCommentModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="openCommentModal"
      @close="togleCommentModal"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getCommentsUpdate"
      @reload-activities="getActivity"
    />

    <EditCommentModal
      v-bind:show="uiProps.showEditComment"
      v-bind:comment="uiProps.comment"
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @close="closeEditComment"
      @refresh="getCommentsUpdate"
      @reload-activities="getActivity"
    />

    <AddAttachmentModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="openAttachmentModal"
      @close="toggleAttachmentModal"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getUploadedAttachments"
      @reload-activities="getActivity"
    />

    <EditSecurityModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
    />

    <LockAccountModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="lockAccountModal"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
      @close-lock-modal="toggleLockAccount"
      @get-activities="getActivity()"
    />

    <UnLockAccountModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :show="unLockAccountModal"
      @close-unlock-modal="toggleUnlockAccount"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
      @get-activities="getActivity()"
    />

    <AddFieldModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :loadingCustomFieldSets="uiProps.loadingCustomFieldSets"
      :loadingAccountCustomFieldSets="uiProps.loadingAccountCustomFieldSets"
      :customFieldSets="uiProps.customFieldSets"
      :accountCustomFieldSets="uiProps.accountCustomFieldSets"
      :show="openAddField"
      @close-add-field="toggleAddField"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @reload="getAccountCustomFieldSets"
      @get-activities="getActivity()"
    />

    <EditFieldModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :loadingCustomFieldSets="uiProps.loadingCustomFieldSets"
      :loadingAccountCustomFieldSets="uiProps.loadingAccountCustomFieldSets"
      :customFieldSets="uiProps.customFieldSets"
      :accountCustomFieldSets="uiProps.accountCustomFieldSets"
      :customFieldSetKey="uiProps.customFieldSetKey"
      :customFieldKey="uiProps.customFieldKey"
      :customFieldValue="uiProps.customFieldValue"
      :customFields="uiProps.customFields"
      :linkValue="uiProps.linkFieldValue ? uiProps.linkFieldValue : null"
      :searchArray="
        uiProps.searchValue && uiProps.searchValue.length
          ? uiProps.searchValue
          : []
      "
      :showEditField="uiProps.editField"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @reload="getAccountCustomFieldSets"
      @close-edit-field="closeEditCustomFieldModal"
    />

    <AddNewFieldModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :loadingCustomFieldSets="uiProps.loadingCustomFieldSets"
      :loadingAccountCustomFieldSets="uiProps.loadingAccountCustomFieldSets"
      :customFieldSets="uiProps.customFieldSets"
      :accountCustomFieldSets="uiProps.accountCustomFieldSets"
      :customFieldSetKey="uiProps.customFieldSetKey"
      :customFields="uiProps.customFields"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @reload="getAccountCustomFieldSets"
    />

    <applyAccruedInterestModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :loadingCustomFieldSets="uiProps.loadingCustomFieldSets"
      :customFieldSets="uiProps.customFieldSets"
      :show="openAppliedFee"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
      @reload="getTransactions"
      @close-apply-fee-modal="toggleAppliedModal"
    />

    <EditPenaltyRate
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="editPenaltyModal"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
      @get-transactions="getTransactions"
      @close-penalty-modal="togglePenaltyModal"
      @get-activities="getActivity()"
    />

    <EditTransactionModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :transaction="uiProps.transaction"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getTransactions"
      @reload-account="getAccountDetails"
      @reload-schedule="getAllReschedules"
    />

    <ViewTransactionModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :transaction="uiProps.transaction"
      :userCanAdjustLoan="userCanAdjustLoan"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getTransactions"
    />

    <EditScheduleModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :loading="uiProps.loadingRepaymentSchedules"
      :repaymentSchedulesProp="
        uiProps.repaymentSchedules ? uiProps.repaymentSchedules : null
      "
      :repaymentSchedulesPropOption="
        uiProps.repaymentSchedules ? uiProps.repaymentSchedules : null
      "
      :show="editScheduleModal"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
      @get-transactions="getTransactions"
      @close-edit-schedule="openEditSchedule"
      @get-schedules="getAccountScheduleDetails"
      @get-activities="getActivity()"
    />

    <CloseWriteOff
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="openWriteOff"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
      @close="toggleWriteOff"
      @get-transactions="getTransactions"
    />

    <ClosePayoffModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="openPayOffModal"
      :userCanClosePayOff="userCanClosePayOff"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @refresh="getAccountDetails"
      @get-transactions="getTransactions"
      @close-payoff="togglePayOffModal"
    />

    <ApplyFeesModal
      :account="
        account_details
          ? account_details.data
            ? account_details.data
            : null
          : null
      "
      :enums="newEnums"
      :show="openAddFeeModal"
      @alertError="alertError"
      @refresh="getAccountDetails"
      @get-transactions="getTransactions"
      @alertSuccess="alertSuccess"
      @close-apply-fee="applyFees"
    />

    <!-- Preview modal -->
    <previewModal
      :show="showPreview"
      @close="togglePreviewModal"
      :imagePreviewDataName="imagePreviewDataName"
      :imagePreviewDataType="imagePreviewDataType"
    />

    <!-- Approve manual disbursement -->
    <DeleteModal
      :show="openManualApprovalModal"
      @close="closeManualApprovalModal"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Are you sure you want to manually disburse this loan?
            </h5>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeManualApprovalModal"
              >
                cancel
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="closeManualApprovalModal"
                v-if="isApproving"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                @click="confirmDisbursement"
                v-else
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!-- <a
      style="visibility: hidden"
      data-modal="#editLoanAccountFieldModal"
      ref="editCustomField"
    ></a> -->
    <a
      style="visibility: hidden"
      data-modal="#addNewLoanAccountFieldModal"
      ref="addCustomField"
    ></a>
    <a
      style="visibility: hidden"
      data-modal="#editTransactionModal"
      ref="editTransaction"
    ></a>
    <a
      style="visibility: hidden"
      data-modal="#viewTransactionModal"
      ref="viewTransaction"
    ></a>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
// import Sidebar from "./components/sidebar";
// import LoanAccountActions from "./components/loanAccountActions";
import FirstLevelDetails from "./components/firstLevelDetails";
import SecondLevelDetails from "./components/secondLevelDetails";
import TransactionsTab from "./components/transactionsTab";
import AccountSchedulesTab from "./components/accountSchedulesTab";
import AccountActivitiesTab from "./components/accountActivitiesTab";
import AccountAttachmentsTab from "./components/accountAttachmentsTab";
import AccountCommentsTab from "./components/accountCommentsTab";
// import { LoanAccountDetails } from "../config_modules/initialStates";

// modals
import EnterRepaymentModal from "./components/modals/enterRepaymentModal";
import CloseRefinanceModal from "./components/modals/closeRefinanceModal";
import CloseRescheduleModal from "./components/modals/closeRescheduleModal";
import CloseWriteOff from "./components/modals/closeWriteOff";
import AddCommentModal from "./components/modals/addCommentModal";
import EditCommentModal from "./components/modals/editCommentModal";
import AddAttachmentModal from "./components/modals/addAttachementModal";
import EditSecurityModal from "./components/modals/editSecuritiesModal";
import LockAccountModal from "./components/modals/lockAccountModal";
import UnLockAccountModal from "./components/modals/unLockAccountModal";
import AddFieldModal from "./components/modals/addFieldModal";
import EditFieldModal from "./components/modals/editFieldModal";
import AddNewFieldModal from "./components/modals/addNewFieldModal";
import applyAccruedInterestModal from "./components/modals/applyAccruedInterestModal";
import EditScheduleModal from "./components/modals/editScheduleModal";
import ClosePayoffModal from "./components/modals/closePayoffModal";
import ApplyFeesModal from "./components/modals/ApplyFee";
import EditPenaltyRate from "./components/modals/editPenaltyRate";
import EditTransactionModal from "./components/modals/editTransactionModal";
import ViewTransactionModal from "./components/modals/viewTransactionModal";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

import { GET_LOAN_ACCOUNT_DETAILS } from "../../../../core/services/store/types";
import branchService from "@/core/services/organization-service/branches.service";
import loanService from "@/core/services/api.loan.service";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import CustomerAside from "@/view/pages/clients/customers/loan_deposit_customer_aside.vue";
import otherService from "@/core/services/other-service/other.service";

import { APPROVE_MANUAL_DISBURSEMENT } from "@/core/services/store/disbursement.module";
export default {
  name: "LoanAccountsIndexForDisbursement",
  components: {
    // Sidebar,
    CustomerAside,
    // LoanAccountActions,
    FirstLevelDetails,
    SecondLevelDetails,
    TransactionsTab,
    EnterRepaymentModal,
    AccountSchedulesTab,
    AccountActivitiesTab,
    AccountAttachmentsTab,
    AccountCommentsTab,
    CloseRefinanceModal,
    CloseRescheduleModal,
    CloseWriteOff,
    AddCommentModal,
    AddAttachmentModal,
    EditSecurityModal,
    LockAccountModal,
    AddFieldModal,
    applyAccruedInterestModal,
    EditScheduleModal,
    ClosePayoffModal,
    ApplyFeesModal,
    EditCommentModal,
    EditPenaltyRate,
    EditFieldModal,
    AddNewFieldModal,
    EditTransactionModal,
    ViewTransactionModal,
    UnLockAccountModal,
    DeleteModal,
    previewModal: () =>
      import(
        /* WebpackChunkName: "previewModal" */ "@/components/deposit/preview/preview.vue"
      ),
  },
  data() {
    return {
      // enums: product_enums,
      allUsers: [],
      allActiveGroups: [],
      editBankDetails: false,
      nameError: "Account verification failed",
      formattedLoanAmount: "",
      form: {
        assignedBranchKey: null,
        selectedCustomer: null,
        accountState: "PENDING APPROVAL",
        accountHolderType: "CLIENT",
        customInformation: [],
        assignedUserKey: null,
      },
      openManualApprovalModal: false,
      accountOfficers: [],
      allEmployers: [],
      employer: {},
      employerKey: "",
      sources: [],
      assets: [],
      isValidating: false,
      payOffRate: 0,
      uiProps: {
        fieldFetchModel: {
          customFieldSetKey: "ec3794533518469692d8929e5708e8c7",
          pageIndex: 1,
          pageSize: 100,
          entityLinkedKey: "8f638a0f02e44aacbd2c776897c4c365",
        },
        accountSchedule: null,
        accountTransactions: null,
        accountActivities: null,
        accountAttachments: null,
        accountComments: null,
        loadingCustomFieldSets: false,
        editField: false,
        customFieldSets: [],
        customFieldSet: null,
        errors: {
          collateral: { valid: true, message: "" },
          guarantees: { valid: true, message: "" },
        },
      },
      actualObject: {
        savingsAccountKey: "",
        type: "",
        guarantorKey: "",
        guarantorType: "",
        amount: "",
        description: "",
        assetName: "",
      },
      assignedBranchKey: null,
      arrearsTolerancePeriod: null,
      arrearsToleranceAmount: null,
      repaymentInstallments: null,
      repaymentPeriodCount: null,
      repaymentPeriodUnit: null,
      confirm: false,
      showInactiveFees: true,
      process: false,
      productTypeKey: null,
      minLoanAmount: null,
      maxLoanAmount: null,
      whatPage: "general",
      branchList: [],
      customers: [],
      scheduleReady: false,
      loadingSchedule: false,
      loadingCustomers: false,
      loadingGuarantorAccounts: false,
      generatingSchedule: false,
      guarantorAccounts: null,
      accountHolderKey: null,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Loan Product has been created",
      },
      disbursementDetails: {
        anticipatedDisbursementDate: null,
        firstRepaymentDate: null,
        bankKey: null,
        bankAccountNumber: null,
        bankAccountName: null,
      },
      bank: {},
      accountArrearsSettings: {
        dateCalculationMethod: "",
        monthlyToleranceDay: 0,
        nonWorkingDaysMethod: "",
        toleranceCalculationMethod: "",
        toleranceFloorAmount: 0,
        tolerancePercentageOfOutstandingPrincipal: null,
        tolerancePeriod: 0,
      },
      guarantees: [],
      repayments: [],
      collateralAssets: [],
      guaranteeModel: {
        source: null,
        account: null,
        amount: null,
        accountList: [],
        sourceList: [],
        loadingSource: false,
        loadingAccounts: false,
        errorAccount: false,
        errorAmount: false,
        errorSource: false,
      },
      collateralModel: {
        assetName: null,
        description: null,
        amount: null,
        errorDescription: false,
        errorAssetName: false,
        errorAmount: false,
      },
      scheduleGenerationError: null,
      defaultFirstRepaymentDueDateOffset: 0,
      gracePeriod: 0,
      gracePeriodType: "",
      interestCalculationMethod: "",
      interestRate: null,
      interestChargeFrequency: "NA",
      loanAmount: 0,
      interestType: null,
      loanName: "",
      notes: "",
      selectedAccountDetails: {},
      loanKey: "",
      validateAmounts: false,
      repaymentReschedulingMethod: null,
      loanFees: {},
      newLoanFees: [],
      filteredLoanFee: [],
      newFees: [],
      openRepaymentModal: false,
      openAddFeeModal: false,
      openCloseRefinance: false,
      openRescheduleModal: false,
      openCommentModal: false,
      openAttachmentModal: false,
      lockAccountModal: false,
      editScheduleModal: false,
      editPenaltyModal: false,
      openPayOffModal: false,
      openAppliedFee: false,
      unLockAccountModal: false,
      openWriteOff: false,
      openAddField: false,
      showPreview: false,
      imagePreviewDataName: "",
      imagePreviewDataType: "",
      confirm_delete: false,
      loanProductStatus: null,
      customerLoanDeposits: null,
      confirm_status: false,
      isApproving: false,
    };
  },
  // data: LoanAccountDetails,
  methods: {
    togleCommentModal() {
      this.openCommentModal = !this.openCommentModal;
    },
    toggleAttachmentModal() {
      this.openAttachmentModal = !this.openAttachmentModal;
    },
    openEditSchedule() {
      this.editScheduleModal = !this.editScheduleModal;
    },
    applyFees() {
      this.openAddFeeModal = !this.openAddFeeModal;
    },
    enterRepayment() {
      this.openRepaymentModal = !this.openRepaymentModal;
    },
    togglePayOffModal() {
      this.openPayOffModal = !this.openPayOffModal;
    },
    toggleAppliedModal() {
      this.openAppliedFee = !this.openAppliedFee;
    },
    toggleAddField() {
      this.openAddField = !this.openAddField;
    },
    togglePenaltyModal() {
      this.editPenaltyModal = !this.editPenaltyModal;
    },
    toggleLockAccount() {
      this.lockAccountModal = !this.lockAccountModal;
    },
    toggleUnlockAccount() {
      this.unLockAccountModal = !this.unLockAccountModal;
    },
    toggleRefinanceModal() {
      this.openCloseRefinance = !this.openCloseRefinance;
    },
    toggleRescheduleModal() {
      this.openRescheduleModal = !this.openRescheduleModal;
    },
    toggleWriteOff() {
      this.openWriteOff = !this.openWriteOff;
    },
    getActivity() {
      this.uiProps.accountActivities = null;
      // const loanKey = this.$route.params.loanAccountKey;
      // this.getActivities(loanKey);
    },
    getAccountDetails() {
      // this.alert.show = false;
      this.isLoading = true;
      this.loanKey = this.$route.params.loanAccountKey;
      this.$store
        .dispatch(GET_LOAN_ACCOUNT_DETAILS, {
          loanAccountKey: this.$route.params.loanAccountKey,
        })
        .then(() => {
          this.isLoading = false;
        });
      if (this.userIsUnauthorized)
        return this.alertWarning(
          "You do not have permission to view this account"
        );
    },
    assignKey() {
      this.loanKey = this.$route.params.loanAccountKey;
    },
    async getCustomerLoanDeposits() {
      this.isLoading = true;
      try {
        const res = await otherService.getAllLoanDepositAccount(
          this.account_details.data.accountHolderKey
        );
        this.customerLoanDeposits = res.data.data;
        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.closedAccount.length > 0
        ) {
          this.customerLoanDeposits.closedAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }

        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.operationalAccount.length > 0
        ) {
          this.customerLoanDeposits.operationalAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    getTransactions(status = false) {
      this.alert.show = false;
      if (this.uiProps.accountTransactions) return;
      const self = this;
      self.uiProps.loadingAccountTransactions = true;
      loanService
        .get(
          `loanaccount/getloanaccountdetailstransactionsbyloanaccountid/${this.$route.params.loanAccountKey}/${status}?pageindex=1&pagesize=20`
        )
        .then((res) => {
          self.uiProps.loadingAccountTransactions = false;
          self.uiProps.accountTransactions = res.data.data;
        })
        .catch(() => {
          self.uiProps.loadingAccountTransactions = false;
        });
    },
    toggleTransactions(value) {
      this.getTransactions(value);
    },
    getSchedules(loanAccountKey = "") {
      const self = this;
      self.uiProps.loadingAccountSchedules = true;
      loanService
        .get(
          `loanaccount/getloanaccountdetailsschedulebyloanaccountid/${loanAccountKey}?pageindex=1&pagesize=100`
        )
        .then((res) => {
          self.uiProps.loadingAccountSchedules = false;
          self.uiProps.accountSchedules = res.data;
        })
        .catch(() => {
          self.uiProps.loadingAccountSchedules = false;
        });
    },
    getAccountScheduleDetails() {
      this.getAccountSchedule(this.$route.params.loanAccountKey);
    },
    getAccountSchedule(loanAccountKey = "") {
      this.alert.show = false;
      if (this.uiProps.accountSchedule) return;
      const self = this;
      self.uiProps.loadingAccountSchedule = true;
      loanService
        .get(`loanaccount/get-loanaccount-schedule/${loanAccountKey}`)
        // .get(`loanaccount/getloanaccountrepaymentschedule/${loanAccountKey}`)
        .then((res) => res.data.data)
        .then((res) => {
          self.uiProps.loadingAccountSchedule = false;
          self.uiProps.accountSchedule = res;
        })
        .catch(() => {
          self.uiProps.loadingAccountSchedule = false;
        });
    },
    getAllReschedules() {
      this.getAccountSchedule(this.$route.params.loanAccountKey);
      this.getRepaymentSchedules(this.$route.params.loanAccountKey);
      this.getSchedules(this.$route.params.loanAccountKey);
    },
    getRepaymentSchedules(loanAccountKey = "") {
      this.alert.show = false;
      const self = this;
      self.uiProps.loadingRepaymentSchedules = true;
      loanService
        // .get(`loanaccount/get-loanaccount-schedule/${loanAccountKey}`)
        .get(`loanaccount/getloanaccountrepaymentschedule/${loanAccountKey}`)
        .then((res) => res.data)
        .then((res) => {
          self.uiProps.loadingRepaymentSchedules = false;
          self.uiProps.repaymentSchedules = res.data;
        })
        .catch(() => {
          self.uiProps.loadingRepaymentSchedules = false;
        });
    },
    getActivities(loanAccountKey = "") {
      this.alert.show = false;
      if (this.uiProps.accountActivities) return;
      const self = this;
      self.uiProps.loadingAccountActivities = true;
      loanService
        .get(
          `loanaccount/getloanaccountdetailsactivitiesbyloanaccountid/${loanAccountKey}`
        )
        .then((res) => {
          self.uiProps.loadingAccountActivities = false;
          self.uiProps.accountActivities = res.data.data;
        })
        .catch((err) => {
          self.uiProps.loadingAccountActivities = false;
          try {
            self.alertError(err.response.message);
          } catch (e) {
            self.alertError(err.message);
          }
        });
    },
    getUploadedAttachments() {
      this.uiProps.accountAttachments = null;
      this.getAttachments(this.$route.params.loanAccountKey);
    },
    imageAlert({ type, message }) {
      if (type === "error") {
        this.alertError(message);
      }

      if (type === "success") {
        this.alertSuccess(message);
      }
    },
    getAttachments(loanAccountKey = "") {
      this.alert.show = false;
      if (this.uiProps.accountAttachments) return;
      const self = this;
      self.uiProps.loadingAccountAttachments = true;
      ApiService.get(
        `GeneralDocument/getUploadedDocumentByHolderkey?holderkey=${loanAccountKey}&pageIndex=1&pageSize=20`
      )
        .then((res) => {
          self.uiProps.loadingAccountAttachments = false;
          self.uiProps.accountAttachments = res.data.data.data;
        })
        .catch(() => {
          self.uiProps.loadingAccountAttachments = false;
        });
    },
    togglePreviewModal() {
      this.showPreview = !this.showPreview;
    },
    previewFile(key, loanKey) {
      this.showPreview = !this.showPreview;
      ApiService.get(
        `GeneralDocument/PreviewDocument?documentKey=${key}&loanKey=${loanKey}`
      )
        .then((response) => {
          this.imagePreviewDataType = response.data.data.fileType;
          this.imagePreviewDataName = response.data.data.fileUrl;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    getCommentsUpdate() {
      this.uiProps.accountComments = null;
      this.getComments();
    },
    getComments() {
      this.alert.show = false;
      if (this.uiProps.accountComments) return;
      const self = this;
      self.uiProps.loadingAccountComments = true;
      GeneralApiService.get(`Comment/${this.$route.params.loanAccountKey}`)
        .then((res) => {
          self.uiProps.loadingAccountComments = false;
          self.uiProps.accountComments = res.data.data;
        })
        .catch(() => {
          self.uiProps.loadingAccountComments = false;
        });
    },
    listBranches() {
      this.alert.show = false;
      const self = this;
      this.uiProps.loadingBranchList = true;
      branchService
        .get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
          filters: [],
        })
        .then((res) => {
          self.uiProps.loadingBranchList = false;
          if (res.status === 200) {
            self.uiProps.branchList = res.data.data.items;
          }
        })
        .catch(() => {
          self.uiProps.loadingBranchList = false;
        });
    },
    confirmStatus(row = null) {
      if (row) this.loanProductKey = row.loanProductKey;
      this.loanProductStatus = row.activated;

      this.alert.show = false;
      if (!this.confirm_status) {
        this.confirm_status = true;
      } else {
        this.confirm_status = false;
      }
    },
    confirmDelete(row) {
      if (row) this.loanProductKey = row.loanProductKey;
      this.loanProductStatus = row.activated;
      this.alert.show = false;
      if (!this.confirm_delete) {
        this.confirm_delete = true;
      } else {
        this.confirm_delete = false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    openEditModal(row = null) {
      this.loanProduct = row;
      this.showEditModal = true;
    },
    openEditComment(c = null) {
      this.uiProps.comment = c;
      this.uiProps.showEditComment = true;
    },
    closeEditComment() {
      this.uiProps.showEditComment = false;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:MM A");
      }
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    editAccount() {
      this.$router.push({
        path: `/loan/account/${this.account_details.data.id}/edit`,
      });
    },
    editRate() {
      this.showPenaltyModal = !this.showPenaltyModal;
    },
    editTransaction(t) {
      this.uiProps.transaction = t;
      this.$refs.editTransaction.click();
    },
    viewTransaction(t) {
      this.uiProps.transaction = t;
      this.$refs.viewTransaction.click();
    },
    approvePartialLoan() {
      const payload = {
        loanProductKey:
          this.account_details.data.adminLoanProduct.loan_product_key,
      };
      loanService
        .post("LoanAccount/changeLoanAccountState", payload)
        .then((res) => res.data.message)
        .then((res) => {
          this.alertSuccess(res);
          this.getAccountDetails();
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    undoClosure() {
      const payload = {
        loanAccountKey: this.$route.params.loanAccountKey,
        userKey: "",
        comments: "",
      };

      loanService
        .post("LoanAccount/undoclosure", payload)
        .then((res) => res.data.message)
        .then((res) => {
          this.getAccountDetails();
          this.getActivities(this.$route.params.loanAccountKey);
          this.alertSuccess(res);
        })
        .catch((error) => {
          // const err = error.toString().substring(6);
          this.alertError(error.response.data.message);
        });
    },
    withdrawLoan() {
      loanService
        .get(
          `LoanAccount/withdrawloanaccount?loanAccountKey=${this.$route.params.loanAccountKey}`
        )
        .then((res) => res.data.message)
        .then((res) => {
          this.getAccountDetails();
          this.getActivities(this.$route.params.loanAccountKey);
          this.alertSuccess(res);
        })
        .catch((error) => {
          const err = error.toString().substring(6);
          this.alertError(err);
        });
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    getCustomFieldSets() {
      this.alert.show = false;
      const self = this;
      self.uiProps.loadingCustomFieldSets = true;
      const p = {
        customFieldSetType: "LoanAccounts",
      };
      GeneralApiService.post("CustomFields/FieldSets", p)
        .then((res) => res.data.data.items)
        .then((res) => {
          self.uiProps.loadingCustomFieldSets = false;
          self.uiProps.customFieldSets = res;
        })
        .catch(() => {
          self.uiProps.loadingCustomFieldSets = false;
        });
    },
    getAccountCustomFieldSets() {
      this.alert.show = false;
      const self = this;
      self.uiProps.loadingAccountCustomFieldSets = true;
      GeneralApiService.get(
        "CustomFields/FieldValues/" +
          this.$route.params.loanAccountKey +
          "/LoanAccounts"
      )
        .then((res) => res.data.data.items)
        .then((res) => {
          self.uiProps.loadingAccountCustomFieldSets = false;
          self.uiProps.accountCustomFieldSets = res;
        });
    },
    async editCustomField(key, keySub, val, data) {
      const getField = data.find((field) => field.fieldKey == keySub);
      const fieldDataType = getField.dataType;
      if (
        ["customer link", "group link", "user link"].includes(
          fieldDataType.toLowerCase()
        )
      ) {
        const linkKey = getField.value.length
          ? getField.value.split("-").at(-2)
          : "";
        const linkName = getField.value.length
          ? getField.value.split("-").at(-1)
          : "";
        val = linkKey;

        let linkFieldValue = { name: linkName, linkKey: linkKey };
        let searchQuery = linkName.split(" ")[0];
        let searchValue = [];

        let searchArea = "";
        fieldDataType.toLowerCase() == "customer link"
          ? "customer"
          : fieldDataType.toLowerCase() == "group link"
          ? "group"
          : "user";

        try {
          const response = await ApiService.post(`GlobalSearch`, {
            searchQuery,
            searchCategory: searchArea,
          });
          searchValue = response.data.data;
        } catch (e) {
          searchValue = [];
        }
        this.uiProps = { ...this.uiProps, linkFieldValue, searchValue };
      } else {
        this.uiProps = this.uiProps;
      }
      this.uiProps.customFieldSetKey = key;
      this.uiProps.customFieldKey = keySub;
      this.uiProps.customFieldValue = val;
      this.uiProps.customFields = data;
      this.uiProps.editField = true;
      // this.$refs.editCustomField.click();
    },
    closeEditCustomFieldModal() {
      this.uiProps.editField = !this.uiProps.editField;
    },
    addNewCustomField(key, data) {
      this.uiProps.customFieldSetKey = key;
      this.uiProps.customFields = data;
      this.$refs.addCustomField.click();
    },

    goBackToDisbursement() {
      this.$router.push({ name: "disbursement" });
    },

    openDisbursement() {
      this.openManualApprovalModal = true;
    },

    closeManualApprovalModal() {
      this.openManualApprovalModal = false;
    },

    confirmDisbursement() {
      try {
        this.isApproving = true;
        const payload = {
          disbursementTransactionKeys: this.$route.params.disbursementKey,
          token: "",
          userKey: "",
        };
        this.$store
          .dispatch(APPROVE_MANUAL_DISBURSEMENT, { ...payload })
          .then(() => {
            this.alertSuccess("Loan Approved Succesfully");
            this.isApproving = false;
            this.closeManualApprovalModal();
            setTimeout(() => {
              this.goBackToDisbursement();
            }, 500);
          })
          .catch((error) => {
            this.alertError(error.response.data.message);
            this.isApproving = false;
          });
      } catch (e) {
        this.alertError(e);
        this.isApproving = false;
      }
    },
  },
  watch: {
    account_details(val) {
      // const self = this;
      if (val) {
        this.getCustomerLoanDeposits();
        if (!val.hasOwnProperty("data")) {
          this.$router.push({ path: `/loan/accounts` });
        }
        //   if(val.data.id){
        //   self.uiProps.loadingAccountSummary = true;
        //   loanService
        //     .get(
        //       `loanaccount/getloanaccountsandsummary/${val.data.accountHolderKey}?pageindex=1&pagesize=1000`
        //     )
        //     .then((res) => {
        //       self.uiProps.loadingAccountSummary = false;
        //       self.uiProps.accountSummary = res.data.accountSummary.data;
        //     })
        //     .catch(() => {
        //       self.uiProps.loadingAccountSummary = false;
        //     });
        //   loanService
        //     .get(
        //       `loanaccount/getloanaccountbalancesandcyclesbycustomerid/${val.data.accountHolderKey}`
        //     )
        //     .then((res) => {
        //       self.uiProps.loadingAccountBalance = false;
        //       self.uiProps.accountBalance = res.data.data;
        //     })
        //     .catch(() => {
        //       self.uiProps.loadingAccountSummary = false;
        //     });
        // }
      }
    },
  },
  computed: {
    ...mapState({
      products: (state) => state.loan.products,
      accounts: (state) => state.loan.accounts,
      user: (state) => state.auth.user,
      newEnums: (state) => state.loan.enums,
      errorCode: (state) => state.loan.errorCode,
      error: (state) => state.loan.error,
      categories: (state) =>
        state.loan.enums.productCategory.map((item, i) => {
          if (i === 0) {
            return { value: null, text: "All" };
          } else {
            return { value: item.id, text: item.name };
          }
        }),
      loading: (state) => state.loan.loading_account_details,
      saving: (state) => state.loan.saving,
      account_details: (state) => state.loan.account_details,
      loanAccountName() {
        return this.account ? this.account.loanName : "_";
      },
      loanPenaltyRate() {
        return this.account ? this.account.penaltyRate : "_";
      },
      accountFullName: function () {
        const { accountHolderType } = this.account_details.data;
        if (accountHolderType && accountHolderType.toLowerCase() == "groups")
          return this.account_details.data &&
            this.account_details.data.customerInformationDetails
            ? this.account_details.data.customerInformationDetails.data
                .customerFirstName
            : "";
        // return (
        //   this.account_details.data.customerInformationDetails.data
        //     .customerFirstName +
        //   " " +
        //   this.account_details.data.customerInformationDetails.data
        //     .customerLastName
        // );
        return this.account_details.data &&
          this.account_details.data.customerInformationDetails
          ? `${this.account_details.data.customerInformationDetails.data.customerFirstName} 
            ${this.account_details.data.customerInformationDetails.data.customerMiddleName} 
            ${this.account_details.data.customerInformationDetails.data.customerLastName}`
          : "";
      },
    }),
    userCanViewLoanAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanViewLoanAccount;
    },
    userCanCreateLoanAccount() {
      const loan = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = loan;
      return permissionsList.CanCreateLoanAccount;
    },
    userCanCloseLoanAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanCloseLoanAccounts;
    },
    userCanWriteOffLoanAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanWriteOffLoanAccounts;
    },
    userCanApplyFees() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanApplyLoanFees;
    },
    userCanAccruedInterest() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanApplyAccruedLoanInterest;
    },
    userCanEditLoanInterest() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanEditInterestRate;
    },
    userCanEditLoanAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanEditLoanAccount;
    },
    userCanClosePayOff() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanPayOffLoan;
    },
    userCanReschedule() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanRescheduleLoanAccount;
    },
    userCanRefinance() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList?.CanRefinaceLoanAccount;
    },
    userCanAdjustLoan() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanApplyLoanAdjustments;
    },
    userCanBackDateTransactions() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanBackDateLoanTransactions;
    },
    userCanLockLoanAccounts() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanLockLoanAccounts;
    },
    userCanWithdraw() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanWithdrawLoanAccounts;
    },
    userCanViewLoanTransactions() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanViewLoanTransactions;
    },
    userCanViewAttachments() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanViewDocuments;
    },
    userCanCreateAttachments() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanCreateDocuments;
    },
    userCanEditAttachments() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanEditDocuments;
    },
    userCanDeleteAttachments() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanDeleteDocuments;
    },
    userCanViewReports() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Reports
      );
      const { permissionsList } = document;
      return permissionsList.CanViewReports;
    },
    userCanCreateReports() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Reports
      );
      const { permissionsList } = document;
      return permissionsList.CanCreateReports;
    },
    accountLoading() {
      return this.loading === true;
    },
    userIsUnauthorized() {
      return this.errorCode == 409 || !this.userCanViewLoanAccount;
    },
    userCanViewComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanViewComments;
    },
    userCanCreateComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanCreateComments;
    },
    userCanEditComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanEditComments;
    },
    userCanDeleteComment() {
      const comment = this.user.scopes.find(
        (item) => item.scopeName === Role.Comments
      );
      const { permissionsList } = comment;
      return permissionsList.CanDeleteComments;
    },
    userCanCreateCustomField() {
      const loan = this.user.scopes.find(
        (item) => item.scopeName === Role.CustomField
      );
      const { permissionsList } = loan;
      return permissionsList.CanCreateCustomField;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  created() {
    this.getAccountDetails();
    // this.getTransactions();
    // this.getSchedules(this.$route.params.loanAccountKey);
    // this.getActivities(this.$route.params.loanAccountKey);
    // this.getAttachments(this.$route.params.loanAccountKey);
    // this.getComments();
    // this.getRepaymentSchedules(this.$route.params.loanAccountKey);
    // this.getCustomFieldSets();
    this.getAccountCustomFieldSets();
    // this.getAccountSchedule(this.$route.params.loanAccountKey);
  },
  mounted() {
    // this.getCustomerLoanDeposits();
    this.assignKey();
  },
};
</script>
<style lang="scss" scoped>
.loan__page__tabs {
  grid-template-columns: repeat(6, 1fr) !important;
}
</style>
